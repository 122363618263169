<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGarden.indent_submission') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- fiscal year -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
              <b-form-group
                label-for="fiscal_year_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.fiscal_year')}}
              </template>
              <b-form-select
                plain
                v-model="search.fiscal_year_id"
                :options="fiscalYearList"
                id="fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- company -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Garden Name" vid="company_id">
              <b-form-group
                label-for="company_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGardenConfig.company_name')}}
              </template>
              <v-select
                :disabled="isGardenAdmin"
                id="company_id"
                v-model="search.company_id"
                :reduce="op => op.value"
                :options="teaCompanyList"
                label="text"
                :state="errors[0] ? false : (valid ? true : null)"
                :placeholder="$t('globalTrans.select')"
                >
              </v-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- garden -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Garden Name" vid="garden_id">
              <b-form-group
                label-for="fiscal_year_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGardenConfig.garden_name')}}
              </template>
              <v-select
                :disabled="isGardenAdmin"
                id="garden_id"
                v-model="search.garden_id"
                :reduce="op => op.value"
                :options="gardenList"
                label="text"
                :state="errors[0] ? false : (valid ? true : null)"
                :placeholder="$t('globalTrans.select')"
                >
              </v-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- status -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Status" vid="status" rules="">
              <b-form-group
                label-for="garden_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.status') }}
                </template>
                <b-form-select
                  plain
                  v-model="search.status"
                  :options="statusList"
                  id="status"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.indent_submission') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
                <div class="d-flex justify-content-end quick-filter-wrapper">
                  <div class="quick-filter-right">
                    <b-form-group
                      :label="$t('menu.perpage')"
                      label-for="per-page-select"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
          </b-row>
          <b-row>
             <b-col md="12" class="table-responsive">
              <b-table-simple hover small bordered responsive>
                <b-thead>
                  <b-tr>
                    <b-th rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th rowspan="2">{{ $t('globalTrans.fiscal_year') }}</b-th>
                    <b-th rowspan="2">{{ $t('teaGardenConfig.tea_garden_name') }}</b-th>
                    <b-th class="text-center" rowspan="2">{{ $t('teaGardenConfig.total_land') }}</b-th>
                    <b-th class="text-center" rowspan="2">{{ $t('teaGarden.total_cultivate_land') }}</b-th>
                    <b-th :colspan="masterFertilizerList.length">{{ $t('teaGarden.req_fer_qty') }}</b-th>
                    <b-th rowspan="2">{{ $t('globalTrans.status') }}</b-th>
                    <b-th rowspan="2">{{ $t('globalTrans.action') }}</b-th>
                  </b-tr>
                  <b-tr v-if="masterFertilizerList.length">
                    <b-th class="text-center" v-for="(item, index) in masterFertilizerList" :key="'fer-' + index">
                      {{ currentLocale === 'en' ? item.text_en: item.text_bn }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listData.length">
                    <b-tr v-for="(item, index) in listData" :key="index">
                      <b-td>{{ $n(index + pagination.slOffset) }}</b-td>
                      <b-td>{{ getFiscalYearName(item.fiscal_year_id) }}</b-td>
                      <b-td>{{ getGardenName(item.garden_id) }}</b-td>
                      <b-td class="text-center"> <span v-if="item.garden && item.garden.land_lease_info">{{$n(item.garden.land_lease_info.total_land)}}</span></b-td>
                      <b-td class="text-center"> {{$n(item.total_cultivate_land)}}</b-td>
                      <b-td class="text-center" v-for="(requestItem, index1) in item.proposals" :key="index1">{{$n(requestItem.reqst_qty)}}</b-td>
                      <b-td>{{ getStatusName(item.status) }}</b-td>
                      <b-td>
                          <router-link class="moc-action-btn moc-view-btn" :to="`/tea-garden-service/garden/indent-submissions-view/${item.id}`" :title="$t('globalTrans.view')">
                            <i class="ri-eye-line"></i>
                          </router-link>
                          <b-button class="moc-action-btn moc-edit-btn" v-if="item.status === 1" @click="editData(item)" :title="$t('globalTrans.edit')">
                            <i class="ri-pencil-fill"></i>
                          </b-button>
                          <b-button @click="nomineeApprovedCopy(item)" v-b-modal.nominee-approved-copy class="moc-action-btn moc-edit-btn" v-if="item.status === 10" :title="$t('teaGarden.submit_approved_copy')">
                            <i class="ri-chat-download-fill"></i>
                          </b-button>
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td :colspan="7 + masterFertilizerList.length" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <!-- nominee approved recipt -->
    <b-modal id="nominee-approved-copy" size="lg" :title="nomineeApprovedCopyTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <NomineeApprovedCopy :app_details="app_details" :key="nomineeApprovedCopyId"/>
    </b-modal>
  </div>
</template>
<script>
import NomineeApprovedCopy from './NomineeApprovedCopy.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { fertilizerRequestList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [ModalBaseMasterList, teaGardenService],
  components: {
    NomineeApprovedCopy
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      isGardenAdmin: false,
      status: 0,
      app_details: {},
      search: {
        fiscal_year_id: 0,
        company_id: '',
        garden_id: '',
        status: 0,
        limit: 20
      },
      gardenList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'globalTrans.fiscal_year', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '12%' } },
        { labels: 'teaGardenConfig.garden_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
        { labels: 'teaGardenConfig.total_land', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.total_cultivate_land', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 7, thStyle: { width: '12%' } }
      ],
      editItemNomineeParentId: 0,
      nomineeApprovedCopyId: 0,
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      this.search.garden_id = this.isGardenAdminCheckGardenId()
    }
    this.loadData()
    this.getGardenList()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.company_id': function (newVal, oldVal) {
      if (newVal) {
        this.getGardenList()
      }
    },
    currentLocale: function (newVal) {
      this.gardenList = this.gardenList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    teaCompanyList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'fiscal_year_id' },
          { key: 'garden_name_bn' },
          { key: 'total_land' },
          { key: 'total_cultivate_land' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'fiscal_year_id' },
          { key: 'garden_name' },
          { key: 'total_land' },
          { key: 'total_cultivate_land' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    nomineeApprovedCopyTitle () {
      return this.$t('teaGarden.submit_approved_copy')
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    masterFertilizerList () {
      return this.$store.state.TeaGardenService.commonObj.masterFertilizerList.filter(item => item.status === 1)
    },
    statusList () {
        const arr = [
          { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
          { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
          { value: 3, text: this.$t('teaGardenBtriService.assigned'), text_en: this.$t('teaGardenBtriService.assigned', 'en'), text_bn: this.$t('teaGardenBtriService.assigned', 'bn') },
          { value: 4, text: this.$t('globalTrans.forward'), text_en: this.$t('globalTrans.forward', 'en'), text_bn: this.$t('globalTrans.forward', 'bn') },
          { value: 5, text: this.$t('globalTrans.verified'), text_en: this.$t('globalTrans.verified', 'en'), text_bn: this.$t('globalTrans.verified', 'bn') },
          { value: 6, text: this.$t('globalTrans.processing'), text_en: this.$t('globalTrans.processing', 'en'), text_bn: this.$t('globalTrans.processing', 'bn') },
          { value: 10, text: this.$t('globalTrans.approved'), text_en: this.$t('globalTrans.approved', 'en'), text_bn: this.$t('globalTrans.approved', 'bn') }
        ]
        return arr
      }
  },
  methods: {
    getStatusName (id) {
      let status = parseInt(id)
      if ([6, 7, 8, 9].includes(status)) {
        status = 6
      }
      const obj = this.statusList.find(item => item.value === status)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    nomineeAdd (item) {
      this.app_details = item
      this.editItemNomineeParentId = item.id
      this.status = item.status
    },
    nomineeApprovedCopy (item) {
      this.app_details = item
      this.nomineeApprovedCopyId = item.id
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, fertilizerRequestList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const garden = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === parseInt(item.garden_id))
          const gardData = {}
          if (typeof garden !== 'undefined') {
            gardData.garden_name = garden.text_en
            gardData.garden_name_bn = garden.text_bn
          } else {
            gardData.garden_name = ''
            gardData.garden_name_bn = ''
          }
          return Object.assign({}, item, { serial: index }, gardData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    editData (item) {
      this.$router.push({ name: 'tea_garden_service.garden.indent_submission_edit', params: { id: item.id }, query: { circular_id: item.circular_id } })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? this.EngBangNum(obj.text_en) : this.EngBangNum(obj.text_bn)
      }
      return ''
    },
    getGardenList () {
      if (this.search.company_id) {
        this.search.garden_id = ''
        this.gardenList = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1 && item.tea_company_id === this.search.company_id)
      } else {
        this.gardenList = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
      }
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
